const TermsAndCondition = () => {
    return ( 
        <div>
                  {/* terms and conditon details */}
      <div class="container mt-5 mb-5">
            <h2 className="text-left">Terms and Conditions</h2>
              <hr/>
               
              <div class="row text-left"> 
              <h4>1. INTRODUCTION</h4>
              <hr/>
              <p>
              Welcome to Daraz.com.bd also hereby known as “we", "us" or "Daraz". We are an online marketplace and these are the terms and conditions governing your access and use of Daraz along with its related sub-domains, sites, mobile app, services and tools (the "Site"). By using the Site, you hereby accept these terms and conditions (including the linked information herein) and represent that you agree to comply with these terms and conditions (the "User Agreement"). This User Agreement is deemed effective upon your use of the Site which signifies your acceptance of these terms. If you do not agree to be bound by this User Agreement please do not access, register with or use this Site. This Site is owned and operate
              </p>
              </div>
              <div class="row text-left"> 
              <h4>2. CONDITIONS OF USE</h4>
              <hr/>
              <p>
              Welcome to Daraz.com.bd also hereby known as “we", "us" or "Daraz". We are an online marketplace and these are the terms and conditions governing your access and use of Daraz along with its related sub-domains, sites, mobile app, services and tools (the "Site"). By using the Site, you hereby accept these terms and conditions (including the linked information herein) and represent that you agree to comply with these terms and conditions (the "User Agreement"). This User Agreement is deemed effective upon your use of the Site which signifies your acceptance of these terms. If you do not agree to be bound by this User Agreement please do not access, register with or use this Site. This Site is owned and operate
              </p>
              </div>
              <div class="row text-left"> 
              <h4>1. INTRODUCTION</h4>
              <hr/>
              <p>
              Welcome to Daraz.com.bd also hereby known as “we", "us" or "Daraz". We are an online marketplace and these are the terms and conditions governing your access and use of Daraz along with its related sub-domains, sites, mobile app, services and tools (the "Site"). By using the Site, you hereby accept these terms and conditions (including the linked information herein) and represent that you agree to comply with these terms and conditions (the "User Agreement"). This User Agreement is deemed effective upon your use of the Site which signifies your acceptance of these terms. If you do not agree to be bound by this User Agreement please do not access, register with or use this Site. This Site is owned and operate
              </p>
              </div>
              <div class="row text-left"> 
              <h4>1. INTRODUCTION</h4>
              <hr/>
              <p>
              Welcome to Daraz.com.bd also hereby known as “we", "us" or "Daraz". We are an online marketplace and these are the terms and conditions governing your access and use of Daraz along with its related sub-domains, sites, mobile app, services and tools (the "Site"). By using the Site, you hereby accept these terms and conditions (including the linked information herein) and represent that you agree to comply with these terms and conditions (the "User Agreement"). This User Agreement is deemed effective upon your use of the Site which signifies your acceptance of these terms. If you do not agree to be bound by this User Agreement please do not access, register with or use this Site. This Site is owned and operate
              </p>
              </div>
              <div class="row text-left"> 
              <h4>1. INTRODUCTION</h4>
              <hr/>
              <p>
              Welcome to Daraz.com.bd also hereby known as “we", "us" or "Daraz". We are an online marketplace and these are the terms and conditions governing your access and use of Daraz along with its related sub-domains, sites, mobile app, services and tools (the "Site"). By using the Site, you hereby accept these terms and conditions (including the linked information herein) and represent that you agree to comply with these terms and conditions (the "User Agreement"). This User Agreement is deemed effective upon your use of the Site which signifies your acceptance of these terms. If you do not agree to be bound by this User Agreement please do not access, register with or use this Site. This Site is owned and operate
              </p>
              </div>
      </div>
        </div>
     );
}
 
export default TermsAndCondition;